const TempDataFooter = {
  firm: {
    name: 'Sampension Livsforsikring A/S',
    address: 'Tuborg Havnevej 14',
    city: '2900 Hellerup',
    phone: '77 33 18 77',
    email: 'info@sampension.dk',
    cvr: '55 83 49 11',
  },

  social: {
    linkedIn: 'https://www.linkedin.com/company/sampension/',
    twitter: 'https://twitter.com/hashtag/sampension',
    facebook: 'https://www.facebook.com/sampension/',
  },
  links: [
    {
      name: 'Tillidsrepræsentanter',
      url: '/page(1d9f64c1-70a9-4615-97e7-7e3aaf5793a2)',
    },
    {
      name: 'Boliger',
      url: '/page(aaccfa96-919d-4171-aeed-f550fe25e5c2)',
    },
    {
      name: 'Kundemagasin',
      url: '/page(477f179e-7329-431b-9377-2cbb942ff01e)',
    },
  ],
  terms: [
    {
      name: 'Teknisk support',
      url: '/page(fc8d6ad2-9718-4d10-9d81-5fea2a550593)',
    },
    {
      name: 'Forbehold og privatlivspolitik',
      url: '/page(e4108810-d7d0-4242-a445-cbbf0af9448f)',
    },
    {
      name: 'Brug af cookies',
      url: '/page(a276af9a-3bf5-48f2-94ac-3a6c11ecffd1)',
    },
  ],
  afdelinger: [
    {
      name: 'arkitektpension.dk',
      url: 'http://www.Arkitektpension.dk',
    },
    {
      name: 'pjdpension.dk',
      url: 'http://www.pjdpension.dk',
    },
    {
      name: '',
      url: '',
    },
  ],
};

export default TempDataFooter;
