const TempDataMegaMenu = {
  megaMenu: [
    {
      id: 'Livsændringer',
      name: 'Card Components',
      menu: [
        {
          id: 'Livsændringer #1',
          name: 'Component Test - Icon Card',
          url: '/TestPages/IconCard',
        },
        {
          id: 'Livsændringer #2',
          name: 'Component Test - Image Card',
          url: '/TestPages/ImageCard',
        },
        {
          id: 'Livsændringer #3',
          name: 'Component Test - Info Card',
          url: '/TestPages/InfoCard',
        },
        {
          id: 'Livsændringer #4',
          name: 'Component Test - WideCard',
          url: '/TestPages/WideCard',
        },
        {
          id: 'Livsændringer #5',
          name: 'Component Test - VisitKort',
          url: '/TestPages/VisitKort',
        },
      ],
    },

    {
      id: 'Pension',
      name: 'Text Component',
      menu: [
        {
          id: 'Pension #1',
          name: 'Component Test - TextArea',
          url: '/TestPages/TextArea',
        },
        {
          id: 'Pension #2',
          name: 'Component Test - Collapsible FAQ',
          url: '/TestPages/FAQ',
        },
        {
          id: 'Pension #3',
          name: 'Component Test - Collapsible TextArea',
          url: '/TestPages/CollapsibleTextArea',
        },
        {
          id: 'Pension #4',
          name: 'Component Test - Slideshow',
          url: '/TestPages/SlideShow',
        },
        {
          id: 'Pension #6',
          name: 'Components Test - HTMLRender / LightBox',
          url: '/TestPages/HtmlLightBox',
        },
        {
          id: 'Pension #7',
          name: 'Components Test - Collapsible',
          url: '/TestPages/Collapsible',
        },
      ],
    },
    {
      id: 'Forsikring',
      name: 'Testing CSS',
      menu: [
        {
          id: 'Forsikring #1',
          name: 'Buttons',
          url: '/TestPages/ButtonsCSS',
        },
        {
          id: 'Forsikring #2',
          name: 'Forms',
          url: '/TestPages/FormsCSS',
        },
      ],
    },
    {
      id: 'Investering og afkast',
      name: 'Test Sider',
      menu: [
        {
          id: 'Investering #1',
          name: 'FrontPage Banner',
          url: '/TestPages/Banner',
        },
        {
          id: 'Investering #2',
          name: 'News Module',
          url: '/TestPages/NewsModule',
        },
        {
          id: 'Investering #3',
          name: 'Youtube Module',
          url: '/TestPages/YoutubeModule',
        },
        {
          id: 'Investering #4',
          name: 'Search Module',
          url: '/TestPages/SearchModule',
        },
        {
          id: 'Investering #5',
          name: 'Tabeller',
          url: '/TestPages/Tabels',
        },
        {
          id: 'ESG',
          name: 'ESG',
          url: '/TestPages/esg',
        },
        // {
        //   id: 'Investering #7',
        //   name: 'Afkast Test Page',
        //   url: '/TestPages/Afkast',
        // },
      ],
    },
    {
      id: 'Hjælp dine medarbejdere',
      name: 'Hjælp dine medarbejdere',
      menu: [
        {
          id: 'Ny medarbejder',
          name: 'Ny medarbejder',
          url: '/',
        },
        {
          id: 'Fleksjobprotokollat',
          name: 'Fleksjobprotokollat',
          url: '/',
        },
        {
          id: 'Frivillig indbetaling',
          name: 'Frivillig indbetaling',
          url: '/',
        },
        {
          id: 'Rådgivning ved sygdom og sundhedsforsikring',
          name: 'Rådgivning ved sygdom og sundhedsforsikring',
          url: '/',
        },
        {
          id: 'Ved kritisk sygdom',
          name: 'Ved kritisk sygdom',
          url: '/',
        },
        {
          id: 'Fratrædelse og orlov',
          name: 'Fratrædelse og orlov',
          url: '/',
        },
        {
          id: 'Ved pensionering',
          name: 'Ved pensionering',
          url: '/',
        },
        {
          id: 'Ved dødsfald',
          name: 'Ved dødsfald',
          url: '/',
        },
        {
          id: 'Få hjælp hos vores socialkonsulenter',
          name: 'Få hjælp hos vores socialkonsulenter',
          url: '/',
        },
      ],
    },
    {
      id: 'Indberetning og betaling',
      name: 'Indberetning og betaling',
      menu: [
        {
          id: 'Indberetning og betaling  #1',
          name: 'Indberetning og betaling  #1',
          url: '/',
        },
        {
          id: 'Indberetning og betaling  #2',
          name: 'Indberetning og betaling  #2',
          url: '/',
        },
        {
          id: 'Indberetning og betaling  #3',
          name: 'Indberetning og betaling  #3',
          url: '/',
        },
        {
          id: 'Indberetning og betaling  #4',
          name: 'Indberetning og betaling  #4',
          url: '/',
        },
        {
          id: 'Indberetning og betaling  #5',
          name: 'Indberetning og betaling  #5',
          url: '/',
        },
        {
          id: 'Indberetning og betaling  #6',
          name: 'Indberetning og betaling  #6',
          url: '/',
        },
        {
          id: 'Indberetning og betaling  #7',
          name: 'Indberetning og betaling  #7',
          url: '/',
        },
        {
          id: 'Indberetning og betaling  #8',
          name: 'Indberetning og betaling  #8',
          url: '/',
        },
        {
          id: 'Indberetning og betaling  #9',
          name: 'Indberetning og betaling  #9',
          url: '/',
        },
      ],
    },
    {
      id: 'Tjenestemandsforsikring',
      name: 'Tjenestemandsforsikring',
      menu: [
        {
          id: 'Tjenestemandsforsikring #1',
          name: 'Tjenestemandsforsikring #1',
          url: '/',
        },
        {
          id: 'Tjenestemandsforsikring #2',
          name: 'Tjenestemandsforsikring #2',
          url: '/',
        },
        {
          id: 'Tjenestemandsforsikring #3',
          name: 'Tjenestemandsforsikring #3',
          url: '/',
        },
        {
          id: 'Tjenestemandsforsikring #4',
          name: 'Tjenestemandsforsikring #4',
          url: '/',
        },
        {
          id: 'Tjenestemandsforsikring #5',
          name: 'Tjenestemandsforsikring #5',
          url: '/',
        },
        {
          id: 'Tjenestemandsforsikring #6',
          name: 'Tjenestemandsforsikring #6',
          url: '/',
        },
        {
          id: 'Tjenestemandsforsikring #7',
          name: 'Tjenestemandsforsikring #7',
          url: '/',
        },
        {
          id: 'Tjenestemandsforsikring #8',
          name: 'Tjenestemandsforsikring #8',
          url: '/',
        },
        {
          id: 'Tjenestemandsforsikring #9',
          name: 'Tjenestemandsforsikring #9',
          url: '/',
        },
      ],
    },
    {
      id: 'Firmapension',
      name: 'Firmapension',
      menu: [
        {
          id: 'Firmapension #1',
          name: 'Firmapension #1',
          url: '/',
        },
        {
          id: 'Firmapension #2',
          name: 'Firmapension #2',
          url: '/',
        },
        {
          id: 'Firmapension #3',
          name: 'Firmapension #3',
          url: '/',
        },
        {
          id: 'Firmapension #4',
          name: 'Firmapension #4',
          url: '/',
        },
        {
          id: 'Firmapension #5',
          name: 'Firmapension #5',
          url: '/',
        },
        {
          id: 'Firmapension #6',
          name: 'Firmapension #6',
          url: '/',
        },
        {
          id: 'Firmapension #7',
          name: 'Firmapension #7',
          url: '/',
        },
        {
          id: 'Firmapension #8',
          name: 'Firmapension #8',
          url: '/',
        },
      ],
    },
    {
      id: 'Job',
      name: 'Job',
      menu: [
        {
          id: 'Job #1',
          name: 'Job #1',
          url: '/',
        },
        {
          id: 'Job #2',
          name: 'Job #2',
          url: '/',
        },
        {
          id: 'Job #3',
          name: 'Job #3',
          url: '/',
        },
        {
          id: 'Job #4',
          name: 'Job #4',
          url: '/',
        },
        {
          id: 'Job #5',
          name: 'Job #5',
          url: '/',
        },
        {
          id: 'Job #6',
          name: 'Job #6',
          url: '/',
        },
        {
          id: 'Job #7',
          name: 'Job #7',
          url: '/',
        },
        {
          id: 'Job #8',
          name: 'Job #8',
          url: '/',
        },
        {
          id: 'Job #9',
          name: 'Job #9',
          url: '/',
        },
      ],
    },
    {
      id: 'Presse',
      name: 'Presse',
      menu: [
        {
          id: 'Presse #1',
          name: 'Presse #1',
          url: '/',
        },
        {
          id: 'Presse #2',
          name: 'Presse #2',
          url: '/',
        },
        {
          id: 'Presse #3',
          name: 'Presse #3',
          url: '/',
        },
        {
          id: 'Presse #4',
          name: 'Presse #4',
          url: '/',
        },
        {
          id: 'Presse #5',
          name: 'Presse #5',
          url: '/',
        },
        {
          id: 'Presse #6',
          name: 'Presse #6',
          url: '/',
        },
        {
          id: 'Presse #7',
          name: 'Presse #7',
          url: '/',
        },
        {
          id: 'Presse #8',
          name: 'Presse #8',
          url: '/',
        },
        {
          id: 'Presse #9',
          name: 'Presse #9',
          url: '/',
        },
      ],
    },
    {
      id: 'Finansiel Information',
      name: 'Finansiel Information',
      menu: [
        {
          id: 'Finansiel Information #1',
          name: 'Finansiel Information #1',
          url: '/',
        },
        {
          id: 'Finansiel Information #2',
          name: 'Finansiel Information #2',
          url: '/',
        },
        {
          id: 'Finansiel Information #3',
          name: 'Finansiel Information #3',
          url: '/',
        },
        {
          id: 'Finansiel Information #4',
          name: 'Finansiel Information #4',
          url: '/',
        },
        {
          id: 'Finansiel Information #5',
          name: 'Finansiel Information #5',
          url: '/',
        },
        {
          id: 'Finansiel Information #6',
          name: 'Finansiel Information #6',
          url: '/',
        },
        {
          id: 'Finansiel Information #7',
          name: 'Finansiel Information #7',
          url: '/',
        },
        {
          id: 'Finansiel Information #8',
          name: 'Finansiel Information #8',
          url: '/',
        },
        {
          id: 'Finansiel Information #9',
          name: 'Finansiel Information #9',
          url: '/',
        },
      ],
    },
    {
      id: 'Struktur og ledelse',
      name: 'Struktur og ledelse',
      menu: [
        {
          id: 'Struktur og ledelse #1',
          name: 'Struktur og ledelse #1',
          url: '/',
        },
        {
          id: 'Struktur og ledelse #2',
          name: 'Struktur og ledelse #2',
          url: '/',
        },
        {
          id: 'Struktur og ledelse #3',
          name: 'Struktur og ledelse #3',
          url: '/',
        },
        {
          id: 'Struktur og ledelse #4',
          name: 'Struktur og ledelse #4',
          url: '/',
        },
        {
          id: 'Struktur og ledelse #5',
          name: 'Struktur og ledelse #5',
          url: '/',
        },
        {
          id: 'Struktur og ledelse #6',
          name: 'Struktur og ledelse #6',
          url: '/',
        },
        {
          id: 'Struktur og ledelse #7',
          name: 'Struktur og ledelse #7',
          url: '/',
        },
        {
          id: 'Struktur og ledelse #8',
          name: 'Struktur og ledelse #8',
          url: '/',
        },
        {
          id: 'Struktur og ledelse #9',
          name: 'Struktur og ledelse #9',
          url: '/',
        },
      ],
    },
  ],
};

export default TempDataMegaMenu;
