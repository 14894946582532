const TempDataNavBar = {
  searchUrl: '/TestPages/SearchModule',
  loginUrl: '/betaLogin',
  menus: {
    subMenus: [
      {
        id: 'Page_1',
        name: 'Kunde',
        url: '/',
        internal: true,
        display: 'Always',
        menuItems: [
          {
            id: 'Livsændringer',
            name: 'Card Components',
          },
          {
            id: 'Pension',
            name: 'Text Component',
          },
          {
            id: 'Forsikring',
            name: 'Testing CSS',
          },
          {
            id: 'Investering og afkast',
            name: 'Test Sider',
          },
        ],
      },
      {
        id: 'Page_2',
        name: 'Arbejdsgiver',
        url: '/page2',
        internal: true,
        display: 'Always',
        menuItems: [
          {
            id: 'Hjælp dine medarbejdere',
            name: 'Hjælp dine medarbejdere',
          },
          {
            id: 'Indberetning og betaling',
            name: 'Indberetning og betaling',
          },
          {
            id: 'Tjenestemandsforsikring',
            name: 'Tjenestemandsforsikring',
          },
          {
            id: 'Firmapension',
            name: 'Firmapension',
          },
        ],
      },
      {
        id: 'Page_3',
        name: 'Om Sampension',
        url: '/page3',
        internal: true,
        display: 'Always',
        menuItems: [
          {
            id: 'Job',
            name: 'Job',
          },
          {
            id: 'Presse',
            name: 'Presse',
          },
          {
            id: 'Finansiel Information',
            name: 'Finansiel Information',
          },
          {
            id: 'Struktur og ledelse',
            name: 'Struktur og ledelse',
          },
        ],
      },
      {
        id: 'Page_4',
        name: 'Kontakt',
        url: '/page4',
        internal: true,
        menuItems: [],
      },
      {
        id: 'Page_5',
        name: 'English',
        url: '/page5',
        internal: true,
        menuItems: [],
      },
      {
        id: 'Page_6',
        name: 'Klassisk log ind',
        url: 'https://classic.sampension.dk/Forside/log-ind',
        internal: false,
        menuItems: [],
      },
    ],
  },
};

export default TempDataNavBar;
