// Import Images
import nemid from '../../../assets/Images/image-nemid.png';
import hvaderpension from '../../../assets/Images/image-hvaderpension.png';
import app from '../../../assets/Images/image-appstore-2.png';
import background from '../../../assets/Images/image-skilsmisse.png';
import BannerImageMobile from '../../../assets/Images/bannerMobile.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const Page2data = {
  pageName: 'Page2',
  pageTemplate: 'Template2',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_2',
    pageTitle: 'Sampension',
    pageDescription: 'Sampensions web site',
    bannerImage: background,
    mobile: BannerImageMobile,
    bannerContent:
      '<h1>Få overblik over din pension</h1><p class="intro">Savner du overblik over din pension - hvad indeholder ordningen egentlig, hvor stor er min opsparing, hvor meget indbetaler jeg og sparer jeg nok op? Her er tre indgange til at få overblik.</p>',
    desktopTools: null,
  },
  pageContent: [
    {
      componentName: 'WideCard',
      componentData: {
        id: 1,
        image: nemid,
        imageRight: false,
        text:
          '<h2>Log ind og se dine tal</h1><p class="intro">Den nemmeste måde at få overblik på er at logge ind med NemID. Her kan du se, hvor meget du får udbetalt, når du går på pension, hvis du bliver syg eller hvordan din familie er dækket, hvis du dør. Tallene er bruttotal, det vil sige før skat.</p><p class="btn-bottom-right"><a href="test" class="linkBtn">test</a></p>',
        btnText: 'Log ind og få overblik',
        btnUrl: '/btnUrl1',
      },
    },
    {
      componentName: 'WideCard',
      componentData: {
        id: 2,
        image: app,
        imageRight: true,
        text:
          '<h2>Få det fulde overblik over hele din økonomi</h2><p class="intro">Vil du et spadestik dybere, kan du downloade vores app (eller logge ind) og prøve vores nye Pensionstjek. Her kan du få et hurtigt og enkelt overblik over hele din økonomi, når du går på pension. Du kan se, hvor meget du får udbetalt i pension efter skat og du får også en anbefaling af, om du sparer nok op til pension.</p><p class="btn-bottom-left"><a href="test" class="linkBtn">test</a></p>',
        btnText: 'Start pensionstjek',
        btnUrl: '/btnUrl2',
      },
    },
    {
      componentName: 'WideCard',
      componentData: {
        id: 3,
        image: hvaderpension,
        imageRight: false,
        text:
          '<h2>Hvad er pension?</h2><p class="intro">Vil du vide mere om, hvad din pensionsordning består af, hvordan du er dækket – og hvad pension i dette hele taget er? Så kan du finde svarene her.</p><p class="btn-bottom-right"><a href="test" class="linkBtn">test</a></p>',
        btnText: 'Læs mere',
        btnUrl: '/btnUrl2',
      },
    },
  ],
};

// Exports
export default Page2data;
